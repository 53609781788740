@import "~@/erp/styles/variables/variables.scss";













































































































.platform-product-putaway {
  height: calc(100vh - 45px - 2 * 9px);
  padding: 9px;
  display: flex;
  flex-direction: column;
  .container {
    padding: 0 10px 60px;
    overflow: auto;
    flex: 1;
  }
  .btns {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  /deep/.el-dialog__body {
    padding: 0 20px;
    .tip1 {
      display: flex;
      align-items: center;
      font-size: 16px;
      > i {
        color: #faad14;
        margin-right: 10px;
        font-size: 30px;
      }
    }
    .tip2 {
      margin-top: 10px;
      padding-left: 40px;
    }
  }
}
